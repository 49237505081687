<template>
    <v-container fluid>
      <v-row style="margin-bottom: 100px">
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="3"
        >
          <v-card class="pa-md-5 bg_card">   
            <div id="other_payment" style="height: 385px; width: 100%;"></div>
            <p></p>
          </v-card>
          <v-dialog v-model="dialog_other_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_other_payment"
                        :indeterminate="loading_other_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Other</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Detail Other
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_other_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_other_payment_items"
                                    :items="detail_other_payments"
                                    :options.sync="option_item_other_payments"
                                    :loading="loading_other_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_other_payment"
                                    :page.sync="pagination_other_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amount`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amount) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_other_payment">Close</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="3"
        >
          <v-card class="pa-md-4 bg_card">   
            <div id="shop_summary_last_month" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_shop_summary_last_month) }}</p>
          </v-card>
          <v-dialog v-model="dialog_shop_last" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_shop_last_month"
                        :indeterminate="loading_shop_last_month"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Shop</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Shop Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_shop"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_shops"
                                    :items="detail_shop_summary_lastest"
                                    :options.sync="option_item_shops"
                                    :loading="loading_shop_last_month"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_shops"
                                    :page.sync="pagination_shop"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.unit_price`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.unit_price) }}
                                  </template>
                                  <template v-slot:[`item.amt_item`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_item) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_shop">Close</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="3"
        >
          <v-card class="pa-md-4 bg_card">   
            <div id="shop_summary_this_month" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_shop_summary_this_month) }}</p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="3"
        >
          <v-card class="pa-md-4 bg_card">   
            <div id="piutang" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_piutang) }}</p>
          </v-card>
          <v-dialog v-model="dialog_piutang" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_piutang"
                        :indeterminate="loading_piutang"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Piutang</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Piutang Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_piutang"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_piutangs"
                                    :items="data_piutang"
                                    :options.sync="option_item_piutangs"
                                    :loading="loading_piutang"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_piutangs"
                                    :page.sync="pagination_piutang"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amt_total`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_total) }}
                                  </template>
                                  <template v-slot:[`item.amt_paid`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_paid) }}
                                  </template>
                                  <template v-slot:[`item.amt_dp`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_dp) }}
                                  </template>
                                  <template v-slot:[`item.amt_retur`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_retur) }}
                                  </template>
                                  <template v-slot:[`item.amt_piutang`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_piutang) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_piutang">Close</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="12"
        >
          <v-card class="pa-md-5 bg_card">   
            <div id="payment" style="height: 370px; width: 100%;"></div>
            <p></p>
          </v-card>
          <v-dialog v-model="dialog_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_payment"
                        :indeterminate="loading_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Payment</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Payment Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_payments"
                                    :items="data_item_payments"
                                    :options.sync="option_item_payments"
                                    :loading="loading_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_payments"
                                    :page.sync="pagination_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.inv_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.inv_amt) }}
                                  </template>
                                  <template v-slot:[`item.pay_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.pay_amt) }}
                                  </template>
                                  <template v-slot:[`item.disc_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.disc_amt) }}
                                  </template>
                                  <template v-slot:[`item.owing_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.owing_amt) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_payment">Close</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog_inv_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_inv_payment"
                        :indeterminate="loading_inv_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Invoice Payment</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Invoice Payment Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_inv_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_inv_payments"
                                    :items="data_item_inv_payments"
                                    :options.sync="option_item_inv_payments"
                                    :loading="loading_inv_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_inv_payments"
                                    :page.sync="pagination_inv_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amt_total`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_total) }}
                                  </template>
                                  <template v-slot:[`item.amt_paid`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_paid) }}
                                  </template>
                                  <template v-slot:[`item.amt_dp`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_dp) }}
                                  </template>
                                  <template v-slot:[`item.amt_refund`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_refund) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_inv_payment">Close</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
  
  export default {
    data() {
        return {
          display: 'none',
          data_other_payments:{},
          tr_type_other_payment:'',
          period_other_payment: '',
          dialog_other_payment: false,
          pagination_other_payment: 1,
          detail_other_payments: [],
          loading_other_payment: false,
          total_item_other_payment: 10,
          option_item_other_payments: {},
          header_other_payment_items:[
            {
                text: 'Period',
                align: 'start',
                sortable: true,
                value: 'period',
            },
            { text: 'TR Date', value: 'tr_date' },
            { text: 'TR Type', value: 'tr_type' },
            { text: 'Item Notes', value: 'item_notes' },
            { text: 'Amount', value: 'amount' }
          ],
          data_payments: [],
          period_inv: '',
          dialog_inv_payment: false,
          pagination_inv_payment: 1,
          period: '',
          dialog_payment: false,
          pagination_payment: 1,
          loading_payment: false,
          data_item_payments: [],
          total_item_payments: 10,
          header_item_payments:[
            {
                text: 'Date Pay',
                align: 'start',
                sortable: true,
                value: 'dt_pay',
            },
            { text: 'Period', value: 'period' },
            { text: 'Customer', value: 'nama' },
            { text: 'Inv ID', value: 'inv_id' },
            { text: 'Inv Amount', value: 'inv_amt' },
            { text: 'Pay Amount', value: 'pay_amt' },
            { text: 'Disc Amount', value: 'disc_amt' },
            { text: 'Owing Amount', value: 'owing_amt' }
          ],
          option_item_payments:{},
          loading_inv_payment: false,
          data_item_inv_payments: [],
          total_item_inv_payments: 10,
          pagination_inv_payment: 1,
          header_item_inv_payments:[
            {
                text: 'Period',
                align: 'start',
                sortable: true,
                value: 'period',
            },
            { text: 'Nama', value: 'nama' },
            { text: 'Amt Total', value: 'amt_total' },
            { text: 'Amt Paid', value: 'amt_paid' },
            { text: 'Amt DP', value: 'amt_dp' },
            { text: 'Amt Return', value: 'amt_retur' }
          ],
          option_item_inv_payments:{},
          shop_summary_lastest: [],
          total_shop_summary_last_month: 0,
          shop_summary_recents: [],
          total_shop_summary_this_month: 0,
          pos_shop: '',
          dialog_shop_last: false,
          pagination_shop: 1, 
          last_month: '',
          loading_shop_last_month: false,
          detail_shop_summary_lastest: [],
          total_item_shops: 10,
          option_item_shops:{},
          header_item_shops:[
            {
                text: 'Date Inv',
                align: 'start',
                sortable: true,
                value: 'dt_inv',
            },
            { text: 'POS Belanja', value: 'pos_belanja' },
            { text: 'Inv ID', value: 'inv_id' },
            { text: 'Description', value: 'description' },
            { text: 'Unit Price', value: 'unit_price' },
            { text: 'Qty', value: 'qty' },
            { text: 'Amount Item', value: 'amt_item' }
          ],
          total_piutang: 0,
          dashboardPiutang: [],
          period_piutang: '',
          dialog_piutang: false,
          pagination_piutang: 1,
          loading_piutang: false,
          data_piutang: [],
          total_item_piutangs: 10,
          header_item_piutangs:[
            {
                text: 'Nama',
                align: 'start',
                sortable: true,
                value: 'nama',
            },
            { text: 'Period', value: 'period' },
            { text: 'Amt Total', value: 'amt_total' },
            { text: 'Amt Paid', value: 'amt_paid' },
            { text: 'Amt DP', value: 'amt_dp' },
            { text: 'Amt Retur', value: 'amt_retur' },
            { text: 'Amt Piutang', value: 'amt_piutang' }
          ],
          option_item_piutangs: {}
        }
    },
    async mounted(){
        await axios.get(`${process.env.VUE_APP_URL}/api/mkm/other_payment`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {

          this.data_other_payments = res.data

          this.renderChartBarOtherPayment(this.data_other_payments)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/mkm/shop?last_month=1`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.shop_summary_lastest = res.data ? res.data.data : []
          this.total_shop_summary_last_month = res.data ? res.data.total : 0

          this.renderChartShopLatest(this.shop_summary_lastest)


        });

        await axios.get(`${process.env.VUE_APP_URL}/api/mkm/shop?last_month=0`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.shop_summary_recents = res.data ? res.data.data : []
          this.total_shop_summary_this_month = res.data ? res.data.total : 0

          this.renderChartShop(this.shop_summary_recents)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/mkm/piutang`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dashboardPiutang = res.data ? res.data.data : []
          this.total_piutang = res.data ? res.data.total : 0

          this.renderChartDoghnutPiutang(this.dashboardPiutang)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/mkm/payment`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {

          this.data_payments = res.data

          this.renderChartBarPayment(this.data_payments)

        });
    },
    methods:{
        search_piutang(){
          this.getItemPiutang(1, 10, this.period_piutang)
        },
        search_shop(){
          this.getItemShop(1, 10, this.last_month)
        },
        search_other_payment(){
          this.getItemOtherPayment(1, 10, this.period_other_payment)
        },
        search_payment(){
          this.getItemPayment(1, 10, this.period)
        },
        search_inv_payment(){
          this.getItemInvPayment(1, 10, this.period_inv)
        },
        close_piutang(){
          this.dialog_piutang = false
        },
        close_payment(){
          this.dialog_payment = false
        },
        close_other_payment(){
          this.dialog_other_payment = false
        },
        close_inv_payment(){
          this.dialog_inv_payment = false
        },
        close_shop(){
          this.dialog_shop_last = false
        },
        renderChartBarOtherPayment(datas){
          var chart3 = new CanvasJS.Chart("other_payment", {
                        animationEnabled: true,
                        theme: "light2",
                        title: {
                            text: "Other Payment & Deposit",
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        axisY: {
                          title : "(Million)",
                          labelFormatter: function (e) {
                            return CanvasJS.formatNumber(e.value, "#,#00");
                          },
                          includeZero: false
                        },
                        data: [
                          {
                            type: "column",
                            name: "Other Payment",
                            click: this.onClickOtherPayment,
                            showInLegend: true,
                            indexLabelPlacement: "inside",  
                            indexLabelOrientation: "vertical",
                            color: "#f79b07",
                            yValueFormatString: "###,###.00\"\"",
                            indexLabel: "{y}",
                            dataPoints: datas.reusable_payment
                          },
                          {
                            type: "column",
                            name: "Other Deposit",
                            click: this.onClickOtherPayment,
                            showInLegend: true,
                            indexLabelPlacement: "inside",  
                            indexLabelOrientation: "vertical",
                            color: "#07dbf7",
                            yValueFormatString: "###,###.00\"\"",
                            indexLabel: "{y}",
                            dataPoints: datas.reusable_deposit
                          }
                        ]
                      });
          chart3.render();
        },
        onClickOtherPayment(e){
          this.tr_type_other_payment = e.dataPoint.tr_type
          this.period_other_payment = e.dataPoint.period
          this.dialog_other_payment = true
          this.pagination_other_payment = 1
          this.detail_other_payments = []

          this.getItemOtherPayment(1, 10, this.period_other_payment)
        },
        async getItemOtherPayment(page = 1, itemsPerPage = 10, period){
          this.loading_other_payment = true

          await axios.get(`${process.env.VUE_APP_URL}/api/mkm/other_payment/show?search=${this.$store.state.text}&period=${period}&tr_type=${this.tr_type_other_payment ? this.tr_type_other_payment : ''}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_other_payment = false
              this.detail_other_payments = res.data.data
              this.total_item_other_payment = res.data.total
              this.pagination_other_payment = res.data.current_page
          })
        },
        renderChartBarPayment(datas){
          var chart3 = new CanvasJS.Chart("payment", {
                        animationEnabled: true,
                        theme: "light2",
                        title: {
                            text: "Invoice & Payment Summary",
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        axisX:{
                          title : "(Year.Month)"
                        },
                        axisY:{
                          title : "(Million)"
                        },
                        data: [
                        {
                          type: "column",
                          name: "Invoice Summary",
                          click: this.onClickInvPayment,
                          showInLegend: true,
                          indexLabelPlacement: "inside",  
                          indexLabelOrientation: "vertical",
                          color: "#f79b07",
                          yValueFormatString: "###,###.00\"\"",
                          indexLabel: "{y}",
                          dataPoints: datas.piutang
                        },
                        {
                          type: "column",
                          name: "Payment Summary",
                          click: this.onClickPayment,
                          showInLegend: true,
                          indexLabelPlacement: "inside",  
                          indexLabelOrientation: "vertical",
                          color: "#07dbf7",
                          yValueFormatString: "###,###.00\"\"",
                          indexLabel: "{y}",
                          dataPoints: datas.payment
                        }
                        ]
                      });
          chart3.render();
        },
        onClickInvPayment(e){
          this.period_inv = e.dataPoint.label
          this.dialog_inv_payment = true
          this.pagination_inv_payment = 1

          this.getItemInvPayment(1,10,e.dataPoint.label)
        },
        async getItemInvPayment(page = 1, itemsPerPage = 10, item){
          this.loading_inv_payment = true
          await axios.get(`${process.env.VUE_APP_URL}/api/mkm/payment/show?search=${this.$store.state.text}&period=${this.period_inv ? this.period_inv : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_inv_payment = false
              this.data_item_inv_payments = res.data.data
              this.total_item_inv_payments = res.data.total
              this.pagination_inv_payment = res.data.current_page
          })
        },
        onClickPayment(e){
          this.period = e.dataPoint.label
          this.dialog_payment = true
          this.pagination_payment = 1

          this.getItemPayment(1,10,e.dataPoint.label)
        },
        async getItemPayment(page = 1, itemsPerPage = 10, item){
            this.loading_payment = true

            await axios.get(`${process.env.VUE_APP_URL}/api/mkm/payment/show-payment?search=${this.$store.state.text}&period=${this.period ? this.period : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_payment = false
                this.data_item_payments = res.data.data
                this.total_item_payments = res.data.total
                this.pagination_payment = res.data.current_page
            })
        },
        renderChartShopLatest(data){

          var chart4 = new CanvasJS.Chart("shop_summary_last_month", {
                        animationEnabled: true,
                        theme: "light2",
                        title: {
                            text: "Purchase Summary Last Month",
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickShopLatest,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} ({total})",
                          toolTipContent: "<b>{label}:</b> {y} ({total})",
                          dataPoints: this.shop_summary_lastest
                        }]
                      });
          chart4.render();
        },
        onClickShopLatest(e){
          this.pos_shop = e.dataPoint.ac_num
          this.dialog_shop_last = true
          this.pagination_shop = 1
          this.last_month = 1
          this.getItemShop(1, 10, this.last_month)
        },
        renderChartShop(data){
          var chart5 = new CanvasJS.Chart("shop_summary_this_month", {
                        animationEnabled: true,
                        theme: "light2",
                        title: {
                            text: "Purchase Summary This Month",
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickShop,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} ({total})",
                          toolTipContent: "<b>{label}:</b> {y} ({total})",
                          dataPoints: this.shop_summary_recents
                        }]
                      });
          chart5.render();
        },
        onClickShop(e){
          this.pos_shop = e.dataPoint.ac_num
          this.dialog_shop_last = true
          this.pagination_shop = 1
          this.last_month = 0
          this.getItemShop(1, 10, this.last_month)
        },
        getItemShop(page = 1, itemsPerPage = 10, item){
          this.loading_shop_last_month = true

          axios.get(`${process.env.VUE_APP_URL}/api/mkm/shop/show?last_month=${item}&search=${this.$store.state.text}&pos_belanja=${this.pos_shop ? this.pos_shop : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_shop_last_month = false
                this.detail_shop_summary_lastest = res.data.data
                this.total_item_shops = res.data.total
                this.pagination_shop = res.data.current_page
            })

        },
        renderChartDoghnutPiutang(data){
          var chart2 = new CanvasJS.Chart("piutang", {
                        animationEnabled: true,
                        theme: "light2",
                        title: {
                            text: "Piutang Summary",
                            fontFamily: "Calibri",
                            fontSize: 20
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickPiutang,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} ({total})",
                          toolTipContent: "<b>{label}:</b> {y} ({total})",
                          dataPoints: this.dashboardPiutang
                        }]
                      });
          chart2.render();
        },
        onClickPiutang(e){
          this.period_piutang = e.dataPoint.label
          this.dialog_piutang = true
          this.pagination_piutang = 1
          this.getItemPiutang(1,10,e.dataPoint.label)
        },
        getItemPiutang(page = 1, itemsPerPage = 10, item){
            this.loading_piutang = true

            axios.get(`${process.env.VUE_APP_URL}/api/mkm/piutang/show?search=${this.$store.state.text}&period=${this.period_piutang ? this.period_piutang : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_piutang = false
                this.data_piutang = res.data.data
                this.total_item_piutangs = res.data.total
                this.pagination_piutang = res.data.current_page
            })
        },
    },
    watch: {
        option_item_other_payments:{
          handler () {
              this.loading_other_payment = true
              const { page, itemsPerPage } = this.option_item_other_payments
              if (this.period_other_payment) {
                  this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
              }
          },
          deep: true,
        },
        option_item_payments:{
          handler () {
                this.loading_payment = true
                const { page, itemsPerPage } = this.option_item_payments
                if (this.period) {
                    this.getItemPayment(page, itemsPerPage, this.period)
                }
            },
            deep: true,
        },
        option_item_inv_payments:{
          handler () {
                this.loading_inv_payment = true
                const { page, itemsPerPage } = this.option_item_inv_payments
                if (this.period_inv) {
                    this.getItemInvPayment(page, itemsPerPage, this.period_inv)
                }
            },
            deep: true,
        },
        option_item_shops:{
          handler () {
                this.loading_shop_last_month = true
                const { page, itemsPerPage } = this.option_item_shops
                if (this.last_month) {
                    this.getItemShop(page, itemsPerPage, this.last_month)
                }
            },
            deep: true,
        },
        option_item_piutangs:{
          handler () {
                this.loading_piutang = true
                const { page, itemsPerPage } = this.option_item_piutangs
                if (this.period_piutang) {
                    this.getItemPiutang(page, itemsPerPage, this.period_piutang)
                }
            },
            deep: true,
        },
    }
  }
</script>